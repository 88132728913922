(function ($) {
  function colorbook() {
    var mainHolder;
    var svgObject, svgOutline, svgColor;
    var fillSpeed = 0.15;
    var chosenColor = '#FFFFFF';
    var chosenCursor;

    function swatchClick() {
      chosenColor = $(this).data('color');
      chosenCursor = $(this).data('cursor');

      $('body').removeAttr('class');
      $('body').addClass(chosenCursor);
    }

    function colorMe() {
      TweenMax.to(this, fillSpeed, {fill: chosenColor, stroke: chosenColor});
    }

    $('.my-swatch').on('click', swatchClick);

    $.fn.makeSVGcolor = function (svgURL) {
      mainHolder = this;
      $(this).load(svgURL, function () {
        svgObject = $('svg', this);
        svgColor = $('g:nth-child(2)', svgObject).children();
        svgOutline = $('g:nth-child(1)', svgObject).children();
        $(svgColor).on('click', colorMe);
      });
    };

    $('#rainbow').makeSVGcolor(window.location.protocol + '//' + window.location.host + '/tovertuin/dist/images/regenboog2.svg');
  }

  colorbook();
}(jQuery));
